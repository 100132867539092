import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {
  //CODE_CONVENTION,
  SLIDER_PREATTRIBUTION,
} from 'features/home/constants.simulation';
import { CustomInput } from 'common/components/customFields/CustomInput';
//import useDebounce from 'common/hooks/useDebounce';
//import usePrevious from 'common/hooks/usePrevious';
import InputNumber from 'common/components/customFields/InputNumber';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  SimulationAT,
  updateFieldByArrayAction,
  updateConventionAction,
  //updateSectionAction,
} from '../actions.simulation';
import {
  simulationFields,
  typeClientConst,
  clientBOAConst,
  rachatConst,
  TEL_VALID,
  //COMPANY_NAME,
} from 'features/home/constants.simulation';
//import { CustomDatePicker } from 'common/components/customFields/CustomDatePicker';
import CustomTitle from 'common/components/customFields/CustomTitle';
import Spinner from 'common/components/Spinner';
import PopUpInfo from './PopUpInfo';
import {
  ERROR_CHANGE_TEL,
  //INVALID_DATE_FORMAT,
  INVALID_PHONE_NUMBER,
  ERROR_CLIENT_SALAFIN,
  E3,
} from 'common/utils/validateJs/contants';
//import moment from 'moment';
import { startsWith, isEmpty } from 'lodash';
import Slider from './Slider';
//import { defaultSelectedConvention } from '../reducer.simulation';
import { GlobalError } from 'common/components/customFields/GlobalError';
import { AccountTypes, pushEvent } from 'features/auth/constants';
import { getSimulationInfoByArraySelector } from 'features/home/selector.simulation';
import { LANG_STATE } from 'features/home/constants.simulation';
import { getValidationInfoByArraySelector } from 'features/validationBmceDirect/selector.validation';
import { getSessionStorage } from 'common/utils/sessionStorage.util';
import { ClientTypeSelect } from 'features/home/components/ClientTypeSelect';
import { ClientBOASelect } from './ClientBOASelect';
import { CreditTypeSelect } from './CreditTypeSelect';
import { scrollToElement } from 'common/utils/app.util';
import HighlightElement from './HighlightElement';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  goToSimulatorBtn: {
    display: 'none',
    '@media (max-width: 768px)': {
      display: 'block',
      position: 'fixed',
      left: '5%',
      width: '90%',
      top: 'calc(90vh)',
      zIndex: 99,
    },
    '&.disableFixed': {
      position: 'static',
      margin: '0 0 20px 5%',
    },
  },
  simulationForm: {
    maxWidth: '1200px',
    paddingTop: '40px',
    '@media (max-width: 768px)': {
      width: '100%',
    },
  },
  formContent: {
    position: 'relative',
    padding: '35px 55px',
    '@media (max-width : 480px)': {
      padding: '10px 15px',
      marginBottom: '15px',
    },
  },
  contentSimulation: {
    margin: '20px 0',
  },
  otherEmploye: {
    '& .MuiOutlinedInput-root': {
      background: 'rgba(85, 101, 132, 0.07)',
      border: '1px solid #dedede !important',
      height: '35px!important',
      borderRadius: '15px',
    },
    '& input': {
      background: 'transparent',
      padding: '7px 15px',
    },
  },
  labelPrimary: {
    fontSize: '0.8rem',
    textAlign: 'left',
    fontFamily: theme.fonts.regular,
    color: theme.secondary.title,
    maxWidth: '255px',
    '@media (max-width: 768px)': {
      maxWidth: '100%',
      marginRight: '0px !important',
      fontSize: '0.875rem',
    },
  },
  labelInformation: {
    fontSize: '1rem',
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    color: theme.primary.title,
    opacity: 1,
    marginLeft: '5px',
    '@media (max-width: 480px)': {
      fontSize: '0.6875rem',
    },
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
  groupFormFooter: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '0 35px 35px',
    '@media (max-width: 600px)': {
      justifyContent: 'center',
      margin: '0 20px 35px',
    },
  },
  buttonSubmitRenseing: {
    borderRadius: '20px',
    textTransform: 'inherit',
    padding: '12px 50px',
    fontFamily: theme.fonts.regular,
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: '#F93276',
    },
    '@media (max-width: 480px)': {
      width: '100%',
    },
  },
  gridItemExtra: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start !important',
  },
  gridItemExtraHolder: {
    fontSize: '0.6875rem',
    textAlign: 'left',
    fontFamily: theme.fonts.regular,
    color: theme.secondary.title,
    maxWidth: '430px',
    paddingLeft: '10px',
    paddingTop: '5px',
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 768px)': {
      padding: '5px 10px',
    },
  },
  switchClientBOA: {
    paddingRight: '0px !important',
  },
  employer: {
    width: '100%',
    borderRadius: '40px',
    borderColor: theme.secondary.title,
    '& .MuiInputBase-root': {
      padding: '2px 15px',
      border: 'solid 1px',
      borderRadius: '40px',
      borderColor: theme.primary.gray,
    },
  },
  rachatMntReste: {
    width: '100%',
  },
  rachatInput: {
    '& .MuiOutlinedInput-root': {
      background: 'rgba(85, 101, 132, 0.07)',
      border: '1px solid #dedede !important',
      height: '35px!important',
      borderRadius: '15px',
    },
    '& input': {
      background: 'transparent',
    },
  },
  radioGroupTypeClient: {
    minWidth: '100%',
    justifyContent: 'space-between',
    '@media (max-width : 480px)': {
      justifyContent: 'flex-start',
      flexDirection: 'column',
      textAlign: 'left',
      marginLeft: '15px',
      marginTop: '0.5em',
      '& .MuiFormControlLabel-root': {
        marginRight: '10px',
        '& .MuiFormControlLabel-label': {
          fontSize: '0.75rem',
          marginLeft: '-5px',
        },
      },
    },
    '@media (max-width : 375px)': {
      justifyContent: 'flex-start',
      marginLeft: '15px',
      marginTop: '0.5em',
      '& .MuiFormControlLabel-root': {
        marginRight: '8px',
        '& .MuiFormControlLabel-label': {
          fontSize: '0.75rem',
          marginLeft: '-5px',
        },
      },
    },
  },
  radioGroup: {
    minWidth: '100%',
    justifyContent: 'space-between',
    '@media (max-width : 480px)': {
      justifyContent: 'flex-start',

      marginLeft: '15px',
      marginTop: '0.5em',
      '& .MuiFormControlLabel-root': {
        marginRight: '10px',
        '& .MuiFormControlLabel-label': {
          fontSize: '0.75rem',
          marginLeft: '-5px',
        },
      },
    },
    '@media (max-width : 375px)': {
      justifyContent: 'flex-start',
      marginLeft: '15px',
      marginTop: '0.5em',
      '& .MuiFormControlLabel-root': {
        marginRight: '8px',
        '& .MuiFormControlLabel-label': {
          fontSize: '0.75rem',
          marginLeft: '-5px',
        },
      },
    },
  },
  openAccountBOA: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginRight: '15px',
  },
  circuleProgress: {
    position: 'absolute',
    zIndex: 1,
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  spinner: {
    width: '80px',
  },
  openBOA: {
    margin: '5px 0',
    display: 'flex',
    '@media (max-width: 480px)': {
      marginTop: '10px',
      padding: '0 !important',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& .MuiGrid-item': {
        display: 'contents',
        fontSize: '0.875rem',
      },
    },
  },
  extraMargeChicker: {
    flexGrow: 1,
    lineHeight: '1.5em !important',
  },
  extraMargeChickerAr: {
    marginRight: '8.5em',
    '@media (max-width: 1024px)': {
      marginRight: '4.5em',
    },
    '@media (max-width: 768px)': {
      marginRight: '2.5em',
    },
  },
  openBOASwitched: {
    '@media (max-width: 480px)': {
      marginTop: '0px !important',
    },
  },
  gridItemSwitch: {
    '@media (max-width: 480px)': {
      padding: '16px !important',
      marginTop: '10px !important',
    },
  },
  gridItemSwitchAr: {
    padding: '20px !important',
    '@media (max-width: 480px)': {
      padding: '16px !important',
      marginTop: '10px !important',
    },
  },
  bold: {
    fontFamily: theme.fonts.bold,
  },
  adjust: {
    marginLeft: '32px',
  },
  gridItemAdjust: {
    marginLeft: '20px',
    '@media (max-width: 768px)': {
      marginLeft: '0px',
    },
  },
  contentStyle: {
    fontSize: '1.0625rem',
    textAlign: 'center',
    fontFamily: theme.fonts.regular,
    color: theme.secondary.title,
    margin: '0% 11% 0% 11%',
    lineHeight: '1.3',
    '@media (max-width: 480px)': {
      fontSize: '0.95rem',
    },
  },
}));

interface Convention {
  minAmount: number;
  maxAmount: number;
  minDuration: number;
  maxDuration: number;
  minMonthlyPayment: number;
  maxMonthlyPayment: number;
}
//const regExpArab = /[\u0600-\u06FF]/;

const Simulation = () => {
  const classes = useStyles();
  // From BMCE DIRECT
  const fromBMCEDirect: any = useSelector(
    getValidationInfoByArraySelector(['data']),
  );
  const preAttributionBmceDirect: any = useSelector(
    getValidationInfoByArraySelector(['preAttribution']),
  );
  const MontantCREDIT = !isEmpty(getSessionStorage('MontantCREDIT'))
    ? parseInt(getSessionStorage('MontantCREDIT'))
    : SLIDER_PREATTRIBUTION.maxAmount;
  const duree = !isEmpty(getSessionStorage('duree'))
    ? parseInt(getSessionStorage('duree'))
    : SLIDER_PREATTRIBUTION.maxDuration;
  const preAttributionToField = () => {
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'selectedConvention', 'maxDuration'],
        duree,
      ),
    );
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'selectedConvention', 'minDuration'],
        SLIDER_PREATTRIBUTION.minDuration,
      ),
    );
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'selectedConvention', 'maxAmount'],
        MontantCREDIT,
      ),
    );
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'fields', 'creditDuration'],
        selectedConvention?.maxDuration,
      ),
    );
  };
  const langSelected: any =
    useSelector(getSimulationInfoByArraySelector(['lang'])) || LANG_STATE.FR;
  const dispatch = useDispatch();

  //const [isOtherEmployer, setIsOtherEmployer] = useState(false);
  //const [isArab, setIsArab] = useState(false);
  const wordingSimulation = useSelector(selectCompWording('simulation'));
  const errorsWording = useSelector(selectCompWording('errors'));
  // const listOfConvention: any | Convention[] = useSelector(
  //   getSimulationInfoByArraySelector(['simulation', 'convention']),
  // );

  const selectedConvention: any | Convention = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'selectedConvention']),
  );

  const simulation: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'fields']),
  );

  const simulationResponse: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'response']),
  );

  const errors: any = useSelector(
    getSimulationInfoByArraySelector(['simulation', 'errors']),
  );

  /*const [listOfConventionVerou, setListOfConventionVerou] = useState<boolean>(
    false,
  );
  const [comptDate, setComptDate] = React.useState(0);
  const [comptCompany, setComptCompany] = React.useState(0);
  const [comptOtherComp, setComptOtherComp] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const [queryCompanyName, setQueryCompanyName] = React.useState<string>('');
  */

  /*useEffect(() => {
    if (queryCompanyName !== '') {
      setIsArab(regExpArab.test(queryCompanyName));
    }
  }, [queryCompanyName, isArab]);
*/
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'O' || event.target.name === 'N') {
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'fields', 'rachat'],
          event.target.value,
        ),
      );
    }
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'fields', event.target.name],
        event.target.value === 'true'
          ? true
          : event.target.value === 'false'
          ? false
          : event.target.value,
      ),
    );

    // if (event.target.name === 'nameOtherEmploye' && event.target.value) {
    //   dispatch(
    //     updateFieldByArrayAction(
    //       ['simulation', 'errors', simulationFields.companyName],
    //       '',
    //     ),
    //   );

    // reset only if is not isOtherEmployer => 'companyName' was not displayed
    // if (!isOtherEmployer)
    //   dispatch(
    //     updateFieldByArrayAction(
    //       ['simulation', 'fields', simulationFields.companyName],
    //       event.target.value,
    //     ),
    //   );

    if (preAttributionBmceDirect) preAttributionToField();

    if (
      event.target.name === simulationFields.typeClient ||
      event.target.name === simulationFields.clientBOA ||
      event.target.name === simulationFields.openAccountBOA
    ) {
      //setQueryCompanyName('');
      // dispatch(
      //   updateFieldByArrayAction(
      //     ['simulation', 'selectedConvention'],
      //     defaultSelectedConvention,
      //   ),
      // );
      // dispatch(
      //   updateFieldByArrayAction(
      //     ['simulation', 'fields', simulationFields.companyName],
      //     '',
      //   ),
      // );
      dispatch(
        updateFieldByArrayAction(['simulation', 'fields', 'rachat'], 'N'),
      );
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'fields', 'rachatMntReste'],
          null,
        ),
      );
      // dispatch(
      //   updateFieldByArrayAction(
      //     ['simulation', 'fields', simulationFields.nameOtherEmploye],
      //     '',
      //   ),
      // );
      dispatch(updateConventionAction([]));
      if (preAttributionBmceDirect) preAttributionToField();

      // hide Autre input
      // setIsOtherEmployer(false);
    }
  };

  /*const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (moment(event.target.value, 'dd/MM/yyyy').isValid()) {
      setComptDate(comptDate + 1);
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'fields', 'comptDate'],
          simulation.comptDate + 1,
        ),
      );
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'fields', event.target.name],
          event.target.value,
        ),
      );
    } else {
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'fields', event.target.name],
          event.target.value,
        ),
      );
    }
  };*/

  /*const handleBlurDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!moment(event.target.value, 'dd/MM/yyyy').isValid()) {
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'errors', simulationFields.birthday],
          INVALID_DATE_FORMAT,
        ),
      );
    }
  }; */

  const handleChangeTel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/, '');
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'fields', event.target.name],
        value,
      ),
    );
    if (
      (!startsWith(value, TEL_VALID.TEL_A) &&
        !startsWith(value, TEL_VALID.TEL_B)) ||
      value?.length !== 10
    ) {
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'errors', simulationFields.phone],
          INVALID_PHONE_NUMBER,
        ),
      );
    }
  };

  /* const debouncedQueryCompanyName = useDebounce(500, queryCompanyName);
  useEffect(
    () => {
      if (
        debouncedQueryCompanyName &&
        debouncedQueryCompanyName.length > 2 &&
        queryCompanyName &&
        debouncedQueryCompanyName.length > 0
      ) {
        dispatch({
          type: SimulationAT.getConvention,
          value: queryCompanyName,
          typeClient: simulation.typeClient,
          simulation,
        });
        setListOfConventionVerou(true);
      } else if (
        simulation.nameOtherEmploye.length > 0 //&&
        // !listOfConventionVerou
      ) {
        dispatch({
          type: SimulationAT.getConvention,
          value: COMPANY_NAME.other,
          simulation,
        });
        setListOfConventionVerou(true);
      } else if (isEmpty(debouncedQueryCompanyName)) {
        dispatch(updateConventionAction([]));
      }
      if (preAttributionBmceDirect) {
        preAttributionToField();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps,
    [dispatch, debouncedQueryCompanyName, simulation.nameOtherEmploye],
  );
  */

  /*const handleChangeCompanyName = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.value.length < 3 && listOfConventionVerou) {
      setListOfConventionVerou(false);
      setIsOtherEmployer(false);
    }

    setQueryCompanyName(event.target.value);
  // clean other company field 
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'fields', simulationFields.nameOtherEmploye],
        '',
      ),
    );
  }; 
  */

  /*const handleChangeOtherCampanyName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setComptOtherComp(comptOtherComp + 1);
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'fields', 'comptOtherComp'],
        simulation.comptOtherComp + 1,
      ),
    );
    handleChange(event);
    dispatch(updateFieldByArrayAction(['simulation', 'convention'], []));
    if (simulation.companyName !== COMPANY_NAME.other) {
      setListOfConventionVerou(false);
    }
    setQueryCompanyName('');
  };
*/
  /*const handleChangeCompanyValue = (
    event: React.ChangeEvent<{}>,
    value: Convention | null,
    reason: any,
  ) => {
    setIsOtherEmployer(false);
    setQueryCompanyName('');
    if (value) {
      setComptCompany(comptCompany + 1);
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'fields', 'comptCompany'],
          simulation.comptCompany + 1,
        ),
      );
      if (value.codeConvention === CODE_CONVENTION.X8) {
        setIsOtherEmployer(true);
      }
      dispatch(
        updateFieldByArrayAction(['simulation', 'selectedConvention'], value),
      );
      if (preAttributionBmceDirect) preAttributionToField();
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'errors', simulationFields.companyName],
          '',
        ),
      );
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'fields', simulationFields.nameOtherEmploye],
          '',
        ),
      );
    } else {
      dispatch(
        updateFieldByArrayAction(['simulation', 'fields', 'rachat'], 'N'),
      );
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'fields', 'rachatMntReste'],
          null,
        ),
      );
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'selectedConvention'],
          defaultSelectedConvention,
        ),
      );
    }
  };*/

  //const prevSelectedConvention = usePrevious(selectedConvention.codeConvention);
  /*useEffect(() => {
    if (prevSelectedConvention) {
      dispatch(
        updateSectionAction('fields', {
          [simulationFields.codeConvention]: selectedConvention.codeConvention,
          [simulationFields.companyName]: selectedConvention.companyName,
        }),
      );
      dispatch(
        updateFieldByArrayAction(
          ['simulation', 'fields', 'creditDuration'],
          selectedConvention?.maxDuration,
        ),
      );
      if (preAttributionBmceDirect) preAttributionToField();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps,
  }, [dispatch, JSON.stringify(selectedConvention)]);*/

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let eventObj = {
      event: 'click_simulation',
      click_label: 'simulez_votre_credit',
      audience: simulation.typeClient,
      is_client: simulation.clientBOA,
      debt_type:
        simulation.rachat === 'O'
          ? 'un_credit_conso_avec_rachat_dencours'
          : 'un_credit_conso_simple',
      element_category: 'body',
    };
    pushEvent(eventObj);
    setTimeout(() => dispatch({ type: SimulationAT.submitSimulation }), 1000);
  };

  const handleClose = () => {
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'fields', 'checkValidateSimulation'],
        false,
      ),
    );
  };

  //const isUserRetired = simulation.typeClient === typeClientConst[2].value;

  const [isFixed, setIsFixed] = useState(true);
  useEffect(() => {
    // Button is displayed after scrolling for window height
    const toggleVisibility = () => {
      if (window.pageYOffset >= window.innerHeight / 1.5) {
        setIsFixed(false);
      } else {
        setIsFixed(true);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToSimilator = () => {
    scrollToElement('simulator', 15);
  };
  return (
    <form
      id={'simulateur'}
      onSubmit={handleSubmit}
      className={classes.simulationForm}
    >
      <Spinner loader={simulationResponse?.loader} />
      <CustomTitle
        title={wordingSimulation?.simulationTitle}
        colorBorder={'blue'}
        positionBorder={''}
        positionTitle={''}
      />
      {/* btn flottant*/}
      <Button
        onClick={scrollToSimilator}
        className={`${classes.goToSimulatorBtn} ${!isFixed && 'disableFixed'}`}
        variant='contained'
        color='secondary'
      >
        {wordingSimulation?.submit}
      </Button>
      <Typography variant='h3' className={classes.contentStyle}>
        <span>{wordingSimulation?.simulationContent}</span>
      </Typography>

      {errors.global &&
        (errors.global[0] === ERROR_CHANGE_TEL ||
          errors.global[0] === ERROR_CLIENT_SALAFIN) && (
          <PopUpInfo
            open={simulation.checkValidateSimulation}
            onClose={handleClose}
            error={errors.global[0]}
          />
        )}
      {errors.global && errors.global.includes(E3) && (
        <GlobalError globalError={errors.global} errorWording={errorsWording} />
      )}
      <Grid id='simulator' className={classes.formContent} container>
        {/** Type client - Vous êtes ? */}
        <Grid item xs={12} sm={4} className={classes.gridItem}>
          <FormLabel className={classes.labelPrimary} component='legend'>
            {wordingSimulation?.typeClient}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.gridItem}>
          <ClientTypeSelect
            className={classes.radioGroupTypeClient}
            name={simulationFields.typeClient}
            onChange={handleChange}
            value={simulation.typeClient}
            listOfElements={typeClientConst}
            wording={wordingSimulation}
            error=''
          />
        </Grid>
        {/** Client BOA - Êtes-vous client BANK OF AFRICA ? */}
        {isEmpty(fromBMCEDirect) && (
          <>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <FormLabel
                className={`${classes.labelPrimary} ${
                  langSelected === LANG_STATE.FR
                    ? classes.extraMargeChicker
                    : classes.extraMargeChickerAr
                }`}
                component='legend'
              >
                {wordingSimulation?.clientBOA}
              </FormLabel>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.gridItem}>
              <ClientBOASelect
                className={classes.radioGroup}
                name={simulationFields.clientBOA}
                onChange={handleChange}
                value={simulation.clientBOA}
                listOfElements={clientBOAConst}
                wording={wordingSimulation}
                error=''
              />
            </Grid>
          </>
        )}
        {!simulation.clientBOA && (
          <>
            <Grid item xs={12} sm={4} className={classes.gridItem}>
              <FormLabel
                className={`${classes.labelPrimary} ${classes.extraMargeChicker}`}
                component='legend'
              >
                {wordingSimulation?.openAccountBOA}
              </FormLabel>
            </Grid>
            <Grid item xs={12} sm={8} className={classes.gridItem}>
              <ClientBOASelect
                className={classes.radioGroup}
                name={simulationFields.openAccountBOA}
                onChange={handleChange}
                value={simulation.openAccountBOA}
                listOfElements={clientBOAConst}
                wording={wordingSimulation}
                error=''
              />
            </Grid>
          </>
        )}
        {/** Date de naissance */}
        {/* <Grid item xs={12} sm={4} className={classes.gridItem}>
          <FormLabel className={classes.labelPrimary} component='legend'>
            {wordingSimulation?.birthday}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.gridItem}>
          <CustomDatePicker
            isMajor={true}
            name={simulationFields.birthday}
            value={simulation.birthday}
            onChange={handleChangeDate}
            format='dd/MM/yyyy'
            onBlur={handleBlurDate}
            placeholder={wordingSimulation?.datePlaceholder}
            hasDefaultBorder={true}
            error={
              errors[simulationFields.birthday]
                ? errorsWording[errors[simulationFields.birthday]]
                : null
            }
          />
        </Grid> */}
        {/** Nom employeur */}
        {/*<Grid item xs={12} sm={4} className={classes.gridItem}>
          <FormLabel className={classes.labelPrimary} component='legend'>
            {!isUserRetired
              ? wordingSimulation?.companyName
              : wordingSimulation?.retiredOtherEmploye}
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={8} className={classes.gridItem}>
          {simulation.openAccountBOA || simulation.clientBOA === true ? (
            <Autocomplete
              freeSolo={false}
              className={classes.employer}
              onChange={handleChangeCompanyValue as any}
              open={open}
              onOpen={() => {
                setOpen(true);
              }}
              onClose={() => {
                setOpen(false);
              }}
              getOptionLabel={(option: any) => {
                return isArab ? option.companyNameAR : option.companyName;
              }}
              options={listOfConvention}
              loading={open}
              loadingText={
                queryCompanyName ? (
                  !listOfConvention.length && queryCompanyName.length < 3 ? (
                    wordingSimulation?.enterCompanyName
                  ) : (
                    <div
                      style={{ cursor: 'pointer' }}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        setIsOtherEmployer(true);
                      }}
                    >
                      {wordingSimulation?.other}
                    </div>
                  )
                ) : (
                  wordingSimulation?.enterCompanyName
                )
              }
              value={
                {
                  [isArab ? 'companyNameAR' : 'companyName']:
                    queryCompanyName !== ''
                      ? queryCompanyName
                      : isArab
                      ? selectedConvention.companyNameAR
                      : selectedConvention.companyName,
                } as any
              }
              renderInput={(params: any) => (
                <CustomInput
                  {...params}
                  onChange={handleChangeCompanyName}
                  name={simulationFields.companyName}
                  value={simulation.companyName}
                  placeholder={
                    !isUserRetired
                      ? wordingSimulation?.companyNamePlaceholder
                      : wordingSimulation?.retiredNamePlaceholder
                  }
                  error={
                    errors[simulationFields.companyName]
                      ? errorsWording[errors[simulationFields.companyName]]
                      : null
                  }
                />
              )}
            />
          ) : (
            <CustomInput
              hasDefaultBorder={true}
              className={classes.otherEmploye}
              onChange={handleChangeOtherCampanyName}
              name={simulationFields.nameOtherEmploye}
              value={simulation.nameOtherEmploye}
              placeholder={wordingSimulation?.nameOtherEmployeHolder}
              error={
                errors[simulationFields.nameOtherEmploye] ||
                errors[simulationFields.companyName]
                  ? errorsWording[
                      errors[simulationFields.nameOtherEmploye] ||
                        errors[simulationFields.companyName]
                    ]
                  : null
              }
            />
          )}
        </Grid>*/}
        {/** Autre employeur */}
        {/*{(simulation.openAccountBOA || simulation.clientBOA === true) &&
          isOtherEmployer && (
            <React.Fragment>
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <FormLabel className={classes.labelPrimary} component='legend'>
                  {isUserRetired
                    ? wordingSimulation?.retiredNameOtherEmploye
                    : simulation.openAccountBOA || simulation.clientBOA
                    ? wordingSimulation?.nameOtherEmploye
                    : wordingSimulation?.companyName}
                </FormLabel>
              </Grid>
              <Grid
                item
                xs={12}
                sm={8}
                className={`${classes.gridItem} ${classes.gridItemExtra}`}
              >
                <CustomInput
                  hasDefaultBorder={true}
                  className={classes.otherEmploye}
                  onChange={handleChangeOtherCampanyName}
                  name={simulationFields.nameOtherEmploye}
                  value={simulation.nameOtherEmploye}
                  placeholder={
                    isUserRetired
                      ? wordingSimulation?.retiredNameOtherEmployeHolder
                      : wordingSimulation?.nameOtherEmployeHolder
                  }
                  error={
                    errors[simulationFields.nameOtherEmploye]
                      ? errorsWording[errors[simulationFields.nameOtherEmploye]]
                      : null
                  }
                />
                <div className={`${classes.gridItemExtraHolder}`}>
                  {isUserRetired
                    ? wordingSimulation?.retiredNameOtherExtraEmployeHolder
                    : wordingSimulation?.nameOtherExtraEmployeHolder}
                </div>
              </Grid>
            </React.Fragment>
          )}*/}
        {/** Rachat - Vous êtes intéressé(e) par (should be hidden if typeClient is professionnel OR client is NOT BOA ) */}
        {simulation.clientBOA &&
          simulation.typeClient !== AccountTypes.professionnel && (
            <>
              <Grid item xs={12} sm={4} className={classes.gridItem}>
                <FormLabel
                  className={`${classes.labelPrimary} ${
                    langSelected === LANG_STATE.FR
                      ? classes.extraMargeChicker
                      : classes.extraMargeChickerAr
                  }`}
                  component='legend'
                >
                  {wordingSimulation?.interesseParRachat}
                </FormLabel>
              </Grid>
              <Grid item xs={12} sm={8} className={classes.gridItem}>
                <CreditTypeSelect
                  className={classes.radioGroup}
                  name={simulationFields.rachat}
                  onChange={handleChange}
                  value={simulation.rachat}
                  listOfElements={rachatConst}
                  wording={wordingSimulation}
                  error=''
                />
              </Grid>
              {/** Rachat - Total de vos encours de crédit */}
              {simulation.rachat === 'O' && isEmpty(fromBMCEDirect) && (
                <>
                  <Grid
                    item
                    xs={12}
                    sm={4}
                    className={`${classes.gridItem} ${classes.info}`}
                  >
                    <FormLabel
                      className={`${classes.labelPrimary} ${
                        langSelected === LANG_STATE.FR
                          ? classes.extraMargeChicker
                          : classes.extraMargeChickerAr
                      }`}
                      component='legend'
                    >
                      {wordingSimulation?.totalRachatEncours}
                    </FormLabel>
                  </Grid>
                  <Grid item xs={12} sm={8} className={classes.gridItem}>
                    <div className={classes.rachatMntReste}>
                      <InputNumber
                        className={classes.rachatInput}
                        onChange={handleChange}
                        name={simulationFields.rachatMntReste}
                        value={simulation.rachatMntReste}
                        placeholder={
                          wordingSimulation?.placeHolderRachatMntReste
                        }
                        suffix={''}
                        hasDefaultBorder={true}
                        endElement={wordingSimulation?.dhs}
                        decimalSeparator=','
                      />
                    </div>
                  </Grid>
                </>
              )}
            </>
          )}

        {/** Slider Montant et Durée */}
        <Slider
          classes={classes}
          wordingSimulation={wordingSimulation}
          errors={errors}
          errorsWording={errorsWording}
          selectedConvention={selectedConvention}
          simulation={simulation}
          simulationResponse={simulationResponse}
          handleChange={handleChange}
          hasOTP={false}
        />
        <Grid item xs={12} sm={4} />
        <Grid item xs={12} sm={8} className={classes.gridItem}>
          <HighlightElement title={wordingSimulation?.freeDiffere} />
        </Grid>

        {/** Numéro de téléphone */}
        <>
          {/* {(simulation.clientBOA ||
            (!simulation.clientBOA && simulation.openAccountBOA)) && ( */}

          {/* )} */}
          <Grid item xs={12} sm={4} className={classes.gridItem}>
            <FormLabel className={classes.labelPrimary} component='legend'>
              {wordingSimulation?.phoneNumber}
            </FormLabel>
          </Grid>
          <Grid item xs={12} sm={8} className={classes.gridItem}>
            <CustomInput
              type='number'
              hasDefaultBorder={true}
              inputProps={{
                maxLength: 10,
                inputMode: 'numeric',
              }}
              onChange={handleChangeTel}
              name={simulationFields.phone}
              value={simulation.phone}
              placeholder={wordingSimulation?.phoneNumberPlaceholder}
              error={
                errors[simulationFields.phone]
                  ? errorsWording[errors[simulationFields.phone]]
                  : null
              }
            />
          </Grid>
        </>
      </Grid>
      <div className={classes.groupFormFooter}>
        <Button
          type='submit'
          className={classes.buttonSubmitRenseing}
          variant='contained'
          color='secondary'
        >
          {wordingSimulation?.submit}
        </Button>
      </div>
    </form>
  );
};

export default Simulation;
