import { makeStyles } from '@material-ui/core/styles';
import { GlobalTheme } from 'features/app/app.theme';
import React from 'react';
import gift from 'assets/svg/giftDiffere.svg';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const highlightRGB = '0, 148, 255';
const useStyles = makeStyles((theme: GlobalTheme) => ({
  titleStyle: {
    textAlign: 'left',
    fontFamily: theme.fonts.medium,
    fontSize: '0.875rem',
    color: `rgba(${highlightRGB}, 1)`,
  },
  img: {
    width: '30px',
    marginRight: '10px',
  },
  box: {
    backgroundColor: `rgba(${highlightRGB}, 0.07)`,
    border: `1px solid rgba(${highlightRGB}, 0.1)`,
    padding: '10px 20px',
    borderRadius: '16px',
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
    alignItems: 'center',
  },
}));

interface HighlightElementProps {
  title: string;
}

const HighlightElement = ({ title }: HighlightElementProps) => {
  const classes = useStyles();
  return (
    <Box className={classes.box}>
      <img src={gift} alt={title} className={classes.img} />
      <Typography className={classes.titleStyle}>{title}</Typography>
    </Box>
  );
};

export default HighlightElement;
