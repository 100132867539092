import { makeStyles } from '@material-ui/core';
import { selectWording } from 'common/wording/selectors.wording';
import SignUpForm from 'features/auth/components/SignUpForm';
import React from 'react';
import { useSelector } from 'react-redux';
import ConfirmationMail from './components/ConfirmationMail';
import { getAuthInfoByArraySelector } from './selector.auth';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '20px 0px',
    '@media (max-width: 1024px)': {
      textAlign: 'center',
    },
    '@media (max-width: 600px)': {
      paddingTop: '5px',
    },
  },
}));
const Auth = () => {
  const wording = useSelector(selectWording);
  const classes = useStyles();
  const sendingEmail = useSelector(
    getAuthInfoByArraySelector(['confirmationMail', 'sendingEmail']),
  );

  return (
    <div className={classes.root}>
      {!sendingEmail && <SignUpForm wording={wording} />}
      {sendingEmail && <ConfirmationMail wording={wording} />}
    </div>
  );
};

export default Auth;
