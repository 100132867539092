import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { has } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { SimulationDetailLine } from 'common/components/SimulationDetailLine';
import { Wording } from 'common/wording/reducer.wording';
import { selectCompWording } from 'common/wording/selectors.wording';
import { GlobalTheme } from 'features/app/app.theme';
import { updateFieldByArrayAction } from 'features/home/actions.simulation';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getPropositionInfoByArraySelector } from './selector.proposition';
import { getSituationInfoByArraySelector } from '../situation/selector.situation';
import { CheckAppreciation } from 'common/components/PrivateRoute';
import { SCORE_PROPOISITION } from 'features/situation/constants.situation';
import { pushPageView } from 'features/auth/constants';

const useStyles = makeStyles((theme: GlobalTheme) => ({
  root: {
    display: 'flex',
    margin: 'auto',
    flexDirection: 'column',
    alignItems: 'center',
    direction: 'ltr',
  },
  header: {
    maxWidth: 581,
    display: 'flex',
    marginBottom: 50,
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  descriptionReject: {
    color: theme.primary.title,
    fontWeight: 'bold',
    margin: '20px',
    width: '100%',
    '@media (max-width: 998px)': {
      width: '80%',
    },
  },
  differeDescText: {
    fontSize: '0.85rem',
    textAlign: 'left',
    color: theme.secondary.title,
  },
  simulationButtonReject: {
    color: '#fff',
    backgroundColor: theme.secondary.main,
    textTransform: 'none',
    border: '0.063em solid #F93276',
    borderRadius: '20px',
    padding: '11px 53px',
    fontSize: '0.875rem',
    '&:hover': {
      backgroundColor: theme.secondary.main,
    },
  },
  simulationDetail: {
    width: '100%',
    border: '0.063em solid #FFF',
    borderRadius: '22px',
    backgroundColor: '#FFF',
  },
  simulationDetailHeaderReject: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 30px 0 30px',
  },
  headerTitleReject: {
    margin: 'auto',
    color: theme.primary.title,
    fontWeight: 'bold',
  },
  headerTitleMax: {
    fontSize: '0.875rem',
    color: theme.secondary.title,
    margin: 'auto',
    textAlign: 'center',
    padding: '0 20 20 0',
  },
  headerMax: {
    width: 'auto',
    fontFamily: theme.fonts.regular,
    color: theme.secondary.title,
    margin: 'auto',
    backgroundColor: '#f5f7fa',
    borderRadius: '20px',
    padding: '11px 53px',
    fontSize: '0.875rem',
    marginTop: '20px',
    marginBottom: '20px',
  },
  headerMaxAmount: {
    fontFamily: theme.fonts.bold,
    color: theme.primary.title,
  },
  simulationDetailBody: {
    padding: '20px 81px 50px 81px',
    '@media (max-width: 490px)': {
      padding: '25px',
    },
  },
  simulationDetailRejectedFooter: {
    margin: '50px',
  },
}));

interface Props {
  withProposal?: boolean;
}

const RejectProposition = ({ withProposal = false }: Props) => {
  const wording: Wording = useSelector(selectCompWording('proposition'));
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const propositionResponse: any = useSelector(
    getPropositionInfoByArraySelector(['proposition', 'fields']),
  );
  const situationSalarie: any = useSelector(
    getSituationInfoByArraySelector(['situationSalarie']),
  );

  const modifySimulation = () => {
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'response', 'creditAmount'],
        propositionResponse.creditAmount,
      ),
    );
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'response', 'creditDuration'],
        propositionResponse.creditDuration,
      ),
    );
    dispatch(
      updateFieldByArrayAction(
        ['simulation', 'response', 'monthlyPayment'],
        propositionResponse.monthlyPayment,
      ),
    );
    history.push('/modify-simulation');
  };

  const isRachat = propositionResponse.rachat === 'O';

  useEffect(() => {
    pushPageView('step_3_rejet_de_la_demande');
  }, []);

  return (
    <CheckAppreciation
      page={[
        SCORE_PROPOISITION.REJET,
        SCORE_PROPOISITION.EN_ATTENTE,
        SCORE_PROPOISITION.NULL,
      ]}
      proposition={propositionResponse}
    >
      <div className={classes.root}>
        <div className={classes.header}>
          {propositionResponse.anomalieCode === 'E3' ? (
            <Typography variant='h5' className={classes.descriptionReject}>
              <React.Fragment>
                {wording?.descriptionRejectNonConvLigne1}
                <br />
                {wording?.descriptionRejectNonConvLigne2}
              </React.Fragment>
            </Typography>
          ) : (
            <Typography variant='h5' className={classes.descriptionReject}>
              {withProposal ? (
                <React.Fragment>
                  {wording?.descriptionRejectMaxLigne1}
                  <br />
                  {wording?.descriptionRejectMaxLigne2}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {wording?.descriptionRejectLigne1}
                  <br />
                  {wording?.descriptionRejectLigne2}
                </React.Fragment>
              )}
            </Typography>
          )}

          <Button
            variant='contained'
            color='primary'
            className={classes.simulationButtonReject}
            onClick={modifySimulation}
          >
            {wording?.redoSimulation}
          </Button>
        </div>

        <div className={classes.simulationDetail}>
          <div className={classes.simulationDetailHeaderReject}>
            <Typography variant='h4' className={classes.headerTitleReject}>
              {wording?.mySimulationDetail}
            </Typography>
          </div>
          <div className={classes.simulationDetailBody}>
            <SimulationDetailLine
              label={wording?.montantCreditDemande}
              value={propositionResponse.creditAmount}
              unit={wording?.dhs}
            />
            {isRachat && (
              <>
                <SimulationDetailLine
                  label={wording?.outstandingAmount}
                  value={propositionResponse.rachatMntReste}
                  unit={wording?.dhs}
                />
                <SimulationDetailLine
                  label={wording?.amountToBeBlocked}
                  value={propositionResponse.debloquedAmount}
                  unit={wording?.dhs}
                />
              </>
            )}
            <SimulationDetailLine
              label={wording?.dureeCredit}
              value={propositionResponse.creditDuration}
              unit={wording?.month}
            />
            <SimulationDetailLine
              label={wording?.mensualite}
              value={propositionResponse.monthlyPayment}
              unit={wording?.dhs}
            />
            <SimulationDetailLine
              label={wording?.assurance}
              value={propositionResponse.amountFeesAssurance}
              unit={wording?.dhs}
            />
            <SimulationDetailLine
              label={wording?.fraisDossier}
              value={
                propositionResponse.amountFeesDemande &&
                propositionResponse.amountFeesDemande?.toString() === '0'
                  ? wording?.free
                  : propositionResponse.amountFeesDemande
              }
              unit={
                propositionResponse.amountFeesDemande &&
                propositionResponse.amountFeesDemande?.toString() === '0'
                  ? ' '
                  : wording?.dhs
              }
            />
            <SimulationDetailLine
              label={wording?.coutTotalCredit}
              value={propositionResponse.totalCostOfCredit}
              unit={wording?.dhs}
            />
            {situationSalarie.fields.isDiffere &&
              has(situationSalarie.fields, 'differe') && (
                <>
                  <SimulationDetailLine
                    label={wording?.startDate}
                    value={propositionResponse.startDate}
                    unit={''}
                  />
                  <SimulationDetailLine
                    label={wording?.optionDiffere}
                    value={situationSalarie.fields.differe}
                    unit={wording?.month}
                  />
                  <Typography className={classes.differeDescText}>
                    {wording?.differeIsFree}
                  </Typography>
                </>
              )}
          </div>
        </div>
      </div>
    </CheckAppreciation>
  );
};

export default RejectProposition;
